import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { isJobCancelable } from 'helpers/utils/job/job'

import { Button, Link, Typography, withStyles } from '@material-ui/core'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import styles from './ToDoneLinkStyles'

const ToDoneLink = ({
  jobIri,
  translate,
  classes,
  showStatus,
  status,
  updateJobStatusAction,
  isLoading,
  jobDiagnostic,
  diagInfos,
}) => {
  const [confirmDialogOpened, setDialogOpened] = useState(false)
  const isNewJobRequired = diagInfos?.newJobRequired

  useEffect(() => {
    jobDiagnostic(jobIri)
  }, [jobIri, jobDiagnostic])

  const openConfirmDialog = () => {
    setDialogOpened(true)
  }

  const handleResponse = ({ currentTarget: { value } }) => {
    if (value === 'true') {
      updateJobStatusAction(jobIri)
    }
    setDialogOpened(!confirmDialogOpened)
  }

  if (!showStatus || !isJobCancelable(status) || isLoading) {
    return null
  }

  return (
    <>
      <Button
        name="toDone"
        className={classes.root}
        color="primary"
        component={Link}
        onClick={openConfirmDialog}
      >
        {isNewJobRequired
          ? translate('resources.jobs.navbar.link.to_finish_and_create_inter')
          : translate('resources.jobs.navbar.link.to_finish')}
      </Button>
      {confirmDialogOpened && (
        <ConfirmDialog
          title={translate('job.to_pending_cancelation.confirm_popin.title')}
          onClose={handleResponse}
        >
          <Typography component="p">
            {translate('job.to_pending_cancelation.confirm_popin.content')}
          </Typography>
        </ConfirmDialog>
      )}
    </>
  )
}

ToDoneLink.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  jobIri: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  showStatus: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateJobStatusAction: PropTypes.func.isRequired,
  jobDiagnostic: PropTypes.func.isRequired,
  diagInfos: PropTypes.shape({
    newJobRequired: PropTypes.bool,
    createdAt: PropTypes.string,
  }).isRequired,
}

export default compose(i18n, withStyles(styles))(ToDoneLink)
